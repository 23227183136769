import React from 'react'
import './LoadingLogo.css'

export const LoadingLogo = <div className="loadingLogo">
		<div className="loadingCenter">
			<img className="loadingBounce" src="/images/logo_HSPW_bounce.png" alt="" />
			<p className="loadingText"><b>Carregando...</b></p>
		</div>
	</div>

export default LoadingLogo