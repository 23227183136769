import React from 'react'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import warning from '../../assets/images/warning_2FA.png'

const Warning2FA = () => {
  const history = useHistory()

  const navigateToUserData = () => {
    history.push('/user-data')
  }

  return (
    <S.Container>
      <div>
        <S.Image src={warning}/>
      </div>
      <p>
        Para garantir a segurança de todos por aqui, algumas partes do sistema
        só <br></br> podem ser acessadas com a autenticação de dois fatores
        habilitada
      </p>
      <S.Button onClick={navigateToUserData}>
        Habilitar autenticação de dois fatores
      </S.Button>
    </S.Container>
  )
}

export default Warning2FA
