import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import loadable from '@loadable/component'
import LoadingLogo from '../../components/LoadingLogo'
import SocketToast from '../../components/SocketToast'

const Login = loadable(() => import('../login'), {
  fallback: LoadingLogo
})

const CandidateReport = loadable(() => import('../admin/candidate-report'), {
  fallback: LoadingLogo
})

const Admin = loadable(() => import('../admin/Admin'), {
  fallback: LoadingLogo
})

const NotFound = loadable(() => import('../not-found'), {
  fallback: LoadingLogo
})
const UserRecoveryPassword = loadable(
  () => import('../user-data/UserRecoveryPassword'),
  { fallback: LoadingLogo }
)
const UserData = loadable(() => import('../user-data/UserData'), {
  fallback: LoadingLogo
})
const AdminCompany = loadable(() => import('../admin/AdminCompany'), {
  fallback: LoadingLogo
})
const UserRequestRecovery = loadable(
  () => import('../user-data/UserRequestRecovery'),
  { fallback: LoadingLogo }
)
const UserFirstAccess = loadable(() => import('../user-first-access'), {
  fallback: LoadingLogo
})

const GoogleCalendar = loadable(() => import('../google-calendar'), {
  fallback: LoadingLogo
})

const RouterComponent = () => {
  return (
    <Router>
      <div style={{ backgroundColor: '#FFF' }}>
        <Switch>
          <Route path="/" exact component={Login} />
          <PrivateRoute path="/admin" component={Admin} global />
          <PrivateRoute path="/admin-company" component={AdminCompany} global />
          <Route path="/login" component={Login} />
          <Route path="/pr" component={UserRecoveryPassword} />
          <Route path="/recovery" component={UserRequestRecovery} />
          <PrivateRoute path="/user-data" component={UserData} global />
          <PrivateRoute path="/dependents-register" />
          <Route path="/candidate-report" component={CandidateReport} />
          <Route path="/user-first-access" component={UserFirstAccess} />
          <Route path="/google-calendar" component={GoogleCalendar} />
          <Route path="*" exact component={NotFound} />
        </Switch>
      </div>
      <SocketToast />
    </Router>
  )
}

export default RouterComponent
