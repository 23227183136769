import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 50px 50px;

  @media (max-width: 500px) {
    padding: 0 30px;
  }

  p {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
  }
`

export const Image = styled.img`
  width: 222px;
  height: 147px;
  margin-bottom: 40px;
`

export const Button = styled.button`
  background-color: #7F5CD1;
  border-radius: 7px;
  border: 0;
  color: #fff;
  padding: 10px 20px;
  margin-top: 20px;
`
