/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react'
import { io } from 'socket.io-client'
import ApiNoblier from '../services/aAPiNoblier'
import { getUrlBase } from '../utils/functions'
import { toast, Toaster, ToastBar } from 'react-hot-toast'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import RestaurantIcon from '@mui/icons-material/Restaurant'
import EmergencyOutlinedIcon from '@mui/icons-material/EmergencyOutlined'
const typesMock = [
  {
    valor: 'ACOLHIMENTO_ATIVO_ANIVERSARIO',
    nome: 'Acolhimento Aniversário',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO_NUTRICIONAL',
    nome: 'Acolhimento Nutricional',
    iconName: 'NutritionIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO_RESULTADO',
    nome: 'Acolhimento Resultado',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'DENUNCIA',
    nome: 'Denúncia',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'RH',
    nome: 'Fale com o RH',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'NUTRI',
    nome: 'Momento Nutri',
    iconName: 'NutritionIcon'
  },
  {
    valor: 'PEDIDO_AJUDA',
    nome: 'Pedido de ajuda',
    iconName: 'PsychologyIcon'
  },
  {
    valor: 'ACOLHIMENTO_ATIVO',
    nome: 'Suporte',
    iconName: 'PsychologyIcon'
  }
]

const iconMap = {
  NutritionIcon: <RestaurantIcon />,
  PsychologyIcon: <EmergencyOutlinedIcon />
}

function SocketToast({ userReducer }) {
  const toastMap = useRef({})
  const activeToasts = useRef(new Set())
  const history = useHistory()
  const expireTime = Infinity

  // Função para navegar para o chat
  const navigateToChat = (chat, isAdmin) => {
    const path = isAdmin ? 'admin' : 'admin-company'
    history.push(`/${path}/company-chat-request/${chat}`)
  }

  useEffect(() => {
    if (!userReducer || !userReducer.user) return

    const isAdmin = userReducer.user.isAdmin

    const client = io(getUrlBase(true), {
      extraHeaders: {
        authorization: `Bearer ${ApiNoblier?.token()}`
      }
    })

    client.emit('enterChat', { chat: userReducer.user.empresa })

    const handleReceivedNotification = data => {
      const { chat_id, sender, pedido_ajuda, tipo } = data

      const chatType = typesMock.find(item => item.valor === tipo)

      const currentPath = history.location.pathname
      const currentSearch = history.location.search

      if (currentPath.includes(chat_id) || currentSearch.includes(chat_id)) {
        return
      }

      if (sender !== 'admin') {
        showToast(chat_id, sender, isAdmin, chatType)
      }
    }

    client.on('receivedNotification', handleReceivedNotification)

    const showToast = (chat_id, sender, isAdmin, chatType) => {
      // Incrementa o contador ou inicializa com 1
      toastMap.current[chat_id] = (toastMap.current[chat_id] || 0) + 1

      // Seleciona o ícone baseado no chatType
      const icon = chatType ? iconMap[chatType.iconName] : null

      // Cria ou atualiza o toast usando toast.custom com o mesmo id
      toast.custom(
        t => (
          <ToastBar toast={t}>
            {({ icon: defaultIcon }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  cursor: 'pointer',
                  gap: 1
                }}
                onClick={() => {
                  navigateToChat(chat_id, isAdmin)
                  // Resetar a contagem explicitamente
                  delete toastMap.current[chat_id]
                  // Fechar o toast
                  toast.dismiss(`toast-${chat_id}`)
                }}
              >
                {icon || defaultIcon}
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {chatType ? `${chatType.nome}` : ''}
                  </Typography>
                  <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                    <Box
                      component="span"
                      sx={{ display: 'inline', fontWeight: 'bold' }}
                    >
                      {sender}
                    </Box>{' '}
                    enviou uma mensagem
                    {toastMap.current[chat_id] > 1
                      ? ` (${toastMap.current[chat_id]})`
                      : ''}
                  </Typography>
                </Box>
                <IconButton
                  sx={{ marginRight: 2 }}
                  onClick={e => {
                    e.stopPropagation() // Evita que o clique propague para o div pai
                    // Resetar a contagem explicitamente
                    delete toastMap.current[chat_id]
                    // Fechar o toast
                    toast.dismiss(`toast-${chat_id}`)
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </ToastBar>
        ),
        {
          id: `toast-${chat_id}`, // ID consistente para permitir atualização
          duration: expireTime,
          onOpen: () => {
            // Marca o chat_id como ativo
            activeToasts.current.add(chat_id)
          },
          onClose: () => {
            // Zera o contador e remove do conjunto de toasts ativos quando o toast fecha ou expira
            delete toastMap.current[chat_id]
            activeToasts.current.delete(chat_id)
          }
        }
      )
    }

    // Cleanup ao desmontar o componente
    return () => {
      client.off('receivedNotification', handleReceivedNotification)
      client.disconnect()
    }
  }, [userReducer, history])

  return (
    <Toaster
      position="bottom-left"
      gutter={8}
      containerStyle={{ marginBottom: '10px' }} // Adiciona margem inferior de 10px
    />
  )
}

// Mapeia o estado do Redux
const mapStateToProps = state => ({
  userReducer: state.userStore.user
})

export default connect(mapStateToProps)(SocketToast)
