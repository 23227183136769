import React, { useEffect, useState } from 'react'
import storage from '../storages/UserStorage'
import TermsOfUse from './TermsOfUse'
import './CookiesBar.css'
import { AiOutlineClose } from 'react-icons/ai'
import { HSPW, bold } from '../utils/functions'
import PrivacyPolicy from './PrivacyPolicy'

const CookiesBar = () => {
  const [openModal, setOpenModal] = useState(false)
  const [show, setShow] = useState(false)
  const [showToU, setShowToU] = useState(false)
  const [showPP, setShowPP] = useState(false)

  useEffect(() => {
    const cookiesState = storage.getAcceptedCookies()

    if (!cookiesState || !cookiesState.accepted) {
      setShow(true)
    }
  }, [])

  const handleToggleToU = () => {
    setShowToU(!showToU)
  }

  const handleTogglePP = () => {
    setShowPP(!showPP)
  }

  const handleToggleModal = () => {
    setOpenModal(!openModal)
  }

  const handleAcceptCookies = () => {
    storage.setAcceptedCookies(
      JSON.stringify({
        accepted: true
      })
    )

    setShow(false)
  }

  return (
    <>
      <div
        className={`overlay ${showToU ? 'fadeIn' : 'fadeOut'}`}
        style={{ width: '100%', zIndex: 3002 }}
      >
        <div
          className="row no-gutters w-100 m-auto"
          style={{ maxWidth: '800px' }}
        >
          <div
            className="card cardCookieShadowBlack"
            style={{ width: '90vw', height: '90vh' }}
          >
            <div className="card-header bg-white border-0 pb-0 px-0">
              <div className="col-12 bg-white text-right px-0">
                <div className="cursor-pointer" onClick={handleToggleToU}>
                  {' '}
                  <AiOutlineClose color="black" size="2em" />
                </div>
              </div>
            </div>
            <div className="card-body m-0 p-0 cookies-terms">
              <TermsOfUse />
            </div>
            <div className="card-footer bg-white border-0 text-center mt-0 mb-2">
              <button
                className="btn-main border-0 btn-sm mr-2"
                onClick={handleToggleToU}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`overlay ${showPP ? 'fadeIn' : 'fadeOut'}`}
        style={{ width: '100%', zIndex: 3002 }}
      >
        <div
          className="row no-gutters w-100 m-auto"
          style={{ maxWidth: '800px' }}
        >
          <div
            className="card cardCookieShadowBlack"
            style={{ width: '90vw', height: '90vh' }}
          >
            <div className="card-header bg-white border-0 pb-0 px-0">
              <div className="col-12 bg-white text-right px-0">
                <div className="cursor-pointer" onClick={handleTogglePP}>
                  {' '}
                  <AiOutlineClose color="black" size="2em" />
                </div>
              </div>
            </div>
            <div className="card-body m-0 p-0 cookies-terms">
              <PrivacyPolicy />
            </div>
            <div className="card-footer bg-white border-0 text-center mt-0 mb-2">
              <button
                className="btn-main border-0 btn-sm mr-2"
                onClick={handleTogglePP}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`overlay ${openModal ? 'fadeIn' : 'fadeOut'}`}
        style={{ width: '100%', zIndex: 3001 }}
      >
        <div
          className="row no-gutters w-100 m-auto"
          style={{ maxWidth: '800px' }}
        >
          <div
            className="card cardCookieShadowBlack cookies"
            style={{ width: '90vw', height: '90vh' }}
          >
            <div className="card-header bg-white pb-0 border-0">
              <div className="row">
                <h6 className="col-12 bold text-center">
                  POLÍTICA DE COOKIES WEBSITE {HSPW()}
                </h6>
              </div>
            </div>
            <div className="card-body p-1 m-0 cookies-terms">
              <p style={{ textAlign: 'justify' }}>
                Nós, da {HSPW()} (“Nós”), estamos comprometidos em resguardar a
                sua privacidade e segurança ao navegar por nossa plataforma,
                assim como nos comprometemos em proteger, da melhor forma
                possível, seus dados pessoais. Para que isso ocorra de forma
                simples e objetiva, elaboramos este documento para esclarecer
                quais informações de Cookies que coletamos e as finalidades
                dessa coleta.
              </p>
              <h6 className="text-left bold">Lembramos que:</h6>
              <ul>
                <li className="text-left bold">DESCRIÇÃO DA EMPRESA</li>
              </ul>
              <p style={{ textAlign: 'justify' }}>
                A {HSPW()} é uma plataforma Health Tech de identificação e
                acompanhamento contínuo da saúde integral de todos os
                colaboradores de uma empresa. Através de uma metodologia
                própria, reúne dados de comportamento relacionados à saúde
                integral, apurados por meio de pesquisas diretas com os
                participantes, e baseados em algoritmos, oferecendo evidências e
                análise de dados para suportar abordagens estratégicas nas
                questões de Saúde e Bem-estar do capital humano das empresas.
              </p>
              <ul>
                <li style={{ textAlign: 'justify' }}>
                  A {HSPW()}, startup lançada em 2020, é uma plataforma Health
                  Tech de identificação e acompanhamento contínuo da saúde
                  integral de todos os colaboradores de uma empresa. Reunindo um
                  Dream Team de sócios como o psiquiatra Jairo Bouer e a
                  jornalista e especialista em bem-estar Glenda Kozlowski, a{' '}
                  {HSPW()} oferece uma solução completa em saúde preventiva e
                  curativa através de seu ecossistema único para clientes e
                  colaboradores.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  <span className="fa-main">
                    &quot;Cuidar das pessoas para que elas cuidem da sua empresa
                    é um investimento com retorno garantido&quot;
                  </span>
                  , com a {HSPW()} a saúde dos colaboradores representa a saúde
                  da empresa.
                </li>
              </ul>
              <h6 className="text-left bold">COOKIES</h6>
              <p style={{ textAlign: 'justify' }}>
                Nós utilizamos alguns cookies para melhorar a experiência de
                nosso Usuários e visitantes dentro da nossa Plataforma, conforme
                melhor explicaremos abaixo:
              </p>
              <h6 className="text-left bold">O que são Cookies?</h6>
              <ul>
                <li style={{ textAlign: 'justify' }}>
                  Um cookie é um dado de navegação. Isto é, é um dado que a
                  plataforma solicita ao seu navegador para armazenar no seu
                  computador ou dispositivo móvel. O cookie permite que o
                  website “lembre” suas ações ou preferências ao longo do tempo.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  A maioria dos navegadores da Internet aceita cookies;
                  entretanto, os Usuários podem configurar seus navegadores para
                  recusar certos tipos de cookies ou cookies específicos. Além
                  disso, os Usuários podem apagar os cookies a qualquer momento.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  Nós da {HSPW()} utilizamos cookies com objetivo de fornecer
                  aos nossos Usuários uma melhor experiência de navegação.
                </li>
              </ul>
              <h6 className="text-left bold">Por que utilizamos cookies?</h6>
              <ul>
                <li style={{ textAlign: 'justify' }}>
                  Utilizamos cookies para potencializar a experiência de nossos
                  Usuários em nosso website, tornando-a mais agradável. Assim,
                  aprendemos como você interage com o nosso conteúdo e buscamos
                  torná-lo mais proveitoso a cada acesso.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  Por exemplo, alguns cookies lembram o seu idioma ou
                  preferências visuais, para que você não precise efetuar estas
                  escolhas repetidamente sempre que visitar um dos nossos
                  websites.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  Além disso, os cookies nos permitem oferecer conteúdos
                  específicos e direcionados, como vídeos no nosso website,
                  anúncios, e direcionamentos de interesse.
                </li>
                <li style={{ textAlign: 'justify' }}>
                  Ainda podemos utilizar os cookies para adaptar o conteúdo e a
                  plataforma de acordo com o dispositivo e as configurações de
                  conexão, ativar funcionalidades como software antivírus e
                  lembrar das credenciais de acesso
                </li>
              </ul>
              <h6 className="text-left bold">
                Quais tipos de Cookies existem?
              </h6>
              <ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Cookies Primários e Cookies de Terceiros
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    Cookies primários são cookies emitidos pelo domínio do
                    website, e geralmente são utilizados para identificar
                    preferências de idioma e local ou facilitar a funcionalidade
                    básica do site.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    Cookies de terceiros pertencem e são administrados por
                    outras empresas, tais como parceiros de negócios da {HSPW()}{' '}
                    ou provedores de serviços. Estes cookies podem ser
                    necessários para produzir certos formulários ou permitir
                    alguma propaganda fora do website da
                    {HSPW()}.
                  </li>
                </ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Cookies de Sessão
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    São cookies temporários que são utilizados para lembrar de
                    Você durante o curso da sua visita ao website, e eles perdem
                    a validade quando você fecha o navegador.
                  </li>
                </ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Cookies Persistentes
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    São utilizados para lembrar suas preferências dentro da
                    plataforma. Estes cookies permanecem no seu desktop ou
                    dispositivo móvel mesmo depois de Você fechar o seu
                    navegador ou efetuar uma reinicialização. Podemos usar estes
                    cookies para analisar o comportamento do Usuário e
                    estabelecer padrões, de modo que possamos melhorar a
                    funcionalidade do nosso website para Você e outros
                    visitantes do nosso website. Estes cookies também permitem
                    que possamos oferecer anúncios segmentados e medir a
                    eficácia da nossa plataforma, assim como as funcionalidades
                    de tais anúncios.
                  </li>
                </ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Como os Cookies são usados para anúncios?
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    Cookies e tecnologias de anúncios tais como ‘web beacons’,
                    ‘pixels’ e ‘tags’ de redes de anúncios nos ajudam a oferecer
                    anúncios relevantes de forma mais eficaz. Eles também nos
                    ajudam a coletar dados consolidados para fins de auditorias,
                    pesquisas e relatórios de desempenho para anunciantes. Os
                    pixels permitem que compreendamos e possamos melhorar a
                    oferta de anúncios para Você, assim como permitem que
                    saibamos quando determinados anúncios já foram apresentados
                    a você. Como o seu navegador pode requisitar anúncios e ‘web
                    beacons’ diretamente de servidores de rede de anúncios,
                    estas redes podem visualizar, editar ou configurar seus
                    próprios cookies, como se você tivesse acessado uma página
                    web do site deles.
                  </li>
                </ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Como os Cookies de terceiros são usados?
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    Para algumas das funções dentro das nossas plataformas
                    podemos utilizar fornecedores terceirizados, por exemplo,
                    quando Você visita uma página com um vídeo incorporado ou
                    proveniente do YouTube. Estes vídeos ou links (e qualquer
                    outro conteúdo de fornecedores terceirizados) podem conter
                    cookies de terceiros. Por conta disso, Nós recomendamos aos
                    nossos Usuários que consultem as Políticas de Privacidade
                    destes terceiros nos seus websites.
                  </li>
                </ul>
                <li style={{ textAlign: 'justify' }} className="bold">
                  Como rejeitar ou apagar Cookies?
                </li>
                <ul>
                  <li style={{ textAlign: 'justify' }}>
                    Você pode escolher Aceitar, Configurar ou rejeitar/bloquear
                    todos ou tipos específicos de cookies configurados através
                    da sua visita à plataforma da {HSPW()}, basta clicar nas
                    preferências de cookies no nosso website. Você pode
                    modificar as suas preferências para o website da {HSPW()}{' '}
                    e/ou os websites de terceiros alterando as configurações do
                    seu navegador.
                  </li>
                  <li style={{ textAlign: 'justify' }}>
                    Lembramos que a maioria dos navegadores aceita cookies
                    automaticamente. Portanto, se Você não quiser que cookies
                    sejam utilizados, Você talvez tenha que apagar ou bloquear
                    cookies ativamente. Se Você rejeitar a utilização de
                    cookies, ainda assim poderá visitar o nosso website, mas
                    algumas das funções poderão não funcionar corretamente.
                  </li>
                </ul>
              </ul>
              <h6 className="text-left bold">COOKIES QUE UTILIZAMOS:</h6>

              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} Armazenamento Local do Navegador</span>
                <br />
                <span>{bold('Tempo de vida:')} Indeterminado</span>
                <br />
                <span>
                  {bold('Finalidade:')} O armazenamento local visa dar uma
                  melhor rapidez na navegação entre páginas armazenando dados
                  comumente usados no sistema, no próprio navegador, não havendo
                  necessidade de recuperá-los através internet toda vez que
                  forem utilizados.
                </span>
                <br />
                <span>{bold('Tipo:')} Armazenamento Local</span>
              </div>
              <div className="dropdown-divider m-2"></div>

              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _hjAbsoluteSessionInProgress</span>
                <br />
                <span>{bold('Tempo de vida:')} 1h30min</span>
                <br />
                <span>
                  {bold('Finalidade:')} Controle de sessão da ferramenta de
                  estudo da experiência do usuário HotJar.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>

              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _hjFirstSeen</span>
                <br />
                <span>{bold('Tempo de vida:')} 1h30min</span>
                <br />
                <span>
                  {bold('Finalidade:')} Controle da ferramenta HotJar.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _hjIncludedInPageviewSample</span>
                <br />
                <span>{bold('Tempo de vida:')} 2min</span>
                <br />
                <span>
                  {bold('Finalidade:')} Controle da ferramenta HotJar.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _hjTLDTest</span>
                <br />
                <span>{bold('Tempo de vida:')} Sessão</span>
                <br />
                <span>
                  {bold('Finalidade:')} Controle da ferramenta HotJar.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _hjid</span>
                <br />
                <span>{bold('Tempo de vida:')} 1 ano</span>
                <br />
                <span>
                  {bold('Finalidade:')} Controle da ferramenta HotJar.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _ga</span>
                <br />
                <span>{bold('Tempo de vida:')} 2 anos</span>
                <br />
                <span>
                  {bold('Finalidade:')} Sessão do Google Analytics que provê
                  informações em tempo real de utilização do site.
                </span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _gat_gtag_UA_180861239_1</span>
                <br />
                <span>{bold('Tempo de vida:')} 1 minuto</span>
                <br />
                <span>{bold('Finalidade:')} Controle do Google Analytics.</span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <div className="text-left">
                <span>{bold('Domínio:')} hspw.com</span>
                <br />
                <span>{bold('Nome:')} _gid</span>
                <br />
                <span>{bold('Tempo de vida:')} 0 seg</span>
                <br />
                <span>{bold('Finalidade:')} Controle do Google Analytics.</span>
                <br />
                <span>{bold('Tipo:')} Analíticos</span>
              </div>
              <div className="dropdown-divider m-2"></div>
              <p style={{ textAlign: 'justify' }}>
                Para saber mais ou entrar em contato com a {HSPW()}, acesse
                nossos{' '}
                <span className="bold text-main cursor-pointer" onClick={handleToggleToU}>
                  Termos de uso
                </span>{' '}
                e{' '}
                <span className="bold text-main cursor-pointer" onClick={handleTogglePP}>
                  Política de Privacidade
                </span>{' '}
                .
              </p>
            </div>
            <div className="card-footer bg-white border-0 text-center mt-0 mb-2">
              <button
                className="btn-main border-0 btn-sm mr-2"
                onClick={handleToggleModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      {show && (
        <div className="fixed-bottom cookies-bar px-4 py-3 row">
          <div className="col-9">
            <p className="text-white font16">
              A HSPW usa cookies para permitir um bom funcionamento do site,
              entender como os usuários o utilizam e melhorar a experiência de
              navegação. Ao clicar em &quot;Aceito&quot; você aceita o uso de
              todos os nossos cookies.
              <span
                className="text-main bold m-0 cursor-pointer"
                onClick={handleToggleModal}
              >
                {' '}
                Saiba mais.
              </span>
            </p>
          </div>
          <div className="col-3 d-flex justify-content-end align-items-center">
            <button
              id="btnAcceptCookies"
              className="btn btn-sm btn-main border-0 cursor-pointer"
              onClick={handleAcceptCookies}
              style={{ width: '150px' }}
            >
              Aceito
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default CookiesBar
