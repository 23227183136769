import axios from 'axios'
import storage from '../storages/UserStorage'
import { getUrlBase, getUrlBaseAdmin } from '../utils/functions'
import preval from 'preval.macro'

const ApiNoblier = {}

const urlBase = getUrlBase(true)
const urlBaseAdmin = getUrlBaseAdmin(true)

ApiNoblier.url = urlBase
ApiNoblier.urlPublic = urlBase

ApiNoblier.token = function () {
  const user = storage.getItemJson()
  if (!user?.access_token) {
    return (window.location.href = '/')
  }

  return user['access_token']
}

ApiNoblier.request = axios.create({
  baseURL: urlBase,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    hspw: JSON.stringify({
      mobile: false,
      versao: preval`module.exports = new Date().getTime();`
    }),
    tzoffset: new Date().getTimezoneOffset()
  },
  validateStatus: function (status) {
    return status >= 200 && status <= 500
  }
})



ApiNoblier.authRequest = (token) =>{
  const api = axios.create({
    baseURL: `${urlBase}/auth`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      hspw: JSON.stringify({
        mobile: false,
        versao: preval`module.exports = new Date().getTime();`
      }),
      tzoffset: new Date().getTimezoneOffset()
    },
    validateStatus: function (status) {
      return status >= 200 && status <= 500
    }
  })
  api.interceptors.request.use(
    config => {
      // Modify baseURL based on the request method
      if (config.method === 'get') {
        config.baseURL = `${urlBaseAdmin}/auth`
      } else if (config.method === 'post' || config.method === 'put') {
        config.baseURL = `${urlBase}/auth`
      }
      // Return the modified config
      return config
    },
    error => {
      // Handle error
      return Promise.reject(error)
    }
  )

  return api
}



export default ApiNoblier
