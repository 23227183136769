import React, { useEffect, useState } from 'react'
import { Redirect, Route, useHistory } from 'react-router-dom'
import Loading from '../loading/Loading'
import storage from '../../storages/UserStorage'
import { addUser } from '../../redux/actions/UserAction'
import { connect } from 'react-redux'
import Warning2FA from '../warning2FA'

const AMBIENTE = process.env.REACT_APP_AMBIENTE || 'local'

const PrivateRoute = ({
  component: Component,
  dispatch,
  global,
  block,
  ...rest
}) => {
  const history = useHistory()

  let [loading, setLoading] = useState(true)
  let [user, setUser] = useState(null)

  useEffect(() => {
    const renderRoutes = async () => {
      const authenticated = await storage.getItemJson()

      if (authenticated && authenticated['access_token']) {
        if (storage.getNickname(authenticated.user['username']) !== '')
          authenticated.user['nickname'] = storage.getNickname(
            authenticated.user['username']
          )

        if (!block) {
          dispatch(addUser(authenticated))
          setUser(authenticated)
        }
        setLoading(false)

        if (authenticated?.user?.situacao === 'RASCUNHO') {
          const voucher = authenticated['user']['voucher']
          history.push(`/user-first-access?voucher=${voucher}`)
        }
      } else {
        setUser(null)
        history.push('/')
      }
    }

    renderRoutes().then()
  }, [dispatch, history])

  const renderComponent = props => {
    const twofaActive = user.user['2FA'] && user.user['2FA']?.ativo

    if (!global && !twofaActive && !['local', 'dev'].includes(AMBIENTE)) {
      return <Warning2FA />
    }

    return <Component {...props} />
  }

  return loading ? (
    <div className="container pt-3">
      <Loading />
    </div>
  ) : (
    <Route
      {...rest}
      render={props =>
        user ? (
          <React.Fragment>{renderComponent(props)}</React.Fragment>
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  )
}

const mapStateToProps = state => ({
  user: state.userStore.user
})

export default connect(mapStateToProps)(PrivateRoute)
