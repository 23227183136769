import React from 'react'
import './TermsOfUse.css'
import { HSPW, bold, underline, mainBold } from '../utils/functions'

const TermsOfUse = ({ showCollapse, showCheck = false, handleCheck }) => {
  return (
    <div className="card card-body border-0 mr-2 p-0 termos overflow-auto">
      <div className="col-12 text-center pb-3">
        <img
          className="img-fluid "
          alt="HSPW"
          src="/images/logo_HSPW_bounce.png"
          style={{ width: '60px', height: 'auto' }}
        />
      </div>

      <h6 className="text-center bold pb-3">TERMOS DE USO DA HSPW</h6>
      <p style={{ textAlign: 'justify' }}>
        Os presentes Termos e Condições regem toda a utilização dos serviços
        oferecidos pela{' '}
        <span className="fa-main-bold">HSPW SAÚDE PREVENTIVA LTDA </span>(“
        {HSPW()}”). Nossos serviços são oferecidos e sujeitos à sua aceitação,
        então, por favor, leia estes termos com atenção antes de acessar ou usar
        nossos Serviços.
        <br />
        <br />
        <span className="fa-main">
          &quot;Olhar para as pessoas, cuidar do futuro&quot;
        </span>
        esse é o lema que move a <span className="fa-main-bold">HSPW</span>, uma
        plataforma que acredita que cuidar da saúde integral dos colaboradores
        tem impacto direto na felicidade, na produtividade e na rentabilidade
        das empresas.{' '}
        <span className="fa-main">Prevenir é melhor que remediar</span>, antes
        de tratar da doença é melhor tratar da saúde.
        <br />
        <br />A {HSPW()} é o software utilizado por várias empresas que buscam
        cuidar da saúde de seus colaboradores através do primeiro e único
        ecossistema de saúde integral, o qual possui uma metodologia baseada em
        algoritmos e inteligência artificial, onde fornece aos funcionários
        questionários fáceis de serem respondidos, com perguntas diárias,
        constantes e fracionadas.
      </p>

      <div className="text-center">
        <img
          className="img-fluid pb-3"
          alt="HSPW"
          src="/images/fa_monitoramos.png"
          style={{ width: '100%', maxWidth: '350px', height: 'auto' }}
        />
      </div>

      <p style={{ textAlign: 'justify' }}>
        <span className="fa-main-bold">Saúde Psicológica:</span> Distúrbios
        psicológicos como Ansiedade, Depressão, Estresse, Burnout, Apatia,
        dentre diversos outros transtornos que se não tratados na raiz podem
        causar problemas graves para os indivíduos e organizações.
        <br />
        <br />
        <span className="fa-main-bold">Saúde Física:</span> Problemas
        relacionados à Saúde Física como Obesidade, Sedentarismo, Insônia, Abuso
        de Álcool, Tabagismo e outros potenciais agravantes.
        <br />
        <br />
        <span className="fa-main-bold">Saúde Financeira:</span> Desinformação
        com relação a finanças é um problema recorrente. A falta deste
        conhecimento traz consequências colaterais para a saúde como um todo,
        especialmente psicológicas e físicas.
        <br />
        <br />
        <span className="fa-main-bold">
          Saúde Organizacional (Fit Cultural):
        </span>{' '}
        A Saúde Organizacional de uma empresa é extremamente importante para o
        crescimento dela, pois mostra que o time está ajustado e adequado para o
        trabalho. Quando um membro da equipe não possui os mesmos
        comportamentos, valores e aderência tende a desmotivá-lo, sendo
        improdutivo para a empresa.
      </p>

      <div className="text-center">
        <img
          className="img-fluid pb-0"
          alt="HSPW"
          src="/images/fa_beneficios_persons.gif"
          style={{ width: '100%', maxWidth: '350px', height: 'auto' }}
        />
      </div>
      <div className="text-center">
        <img
          className="img-fluid pb-3"
          alt="HSPW"
          src="/images/fa_beneficios_text.png"
          style={{ width: '100%', maxWidth: '350px', height: 'auto' }}
        />
      </div>

      <p style={{ textAlign: 'justify' }}>
        A {HSPW()} traz benefícios para todas as partes envolvidas em uma
        relação profissional, isto é, através do cuidados e benefícios
        oferecidos aos colaboradores, as empresas ganham com um ambiente mais
        saudável e produtivo.
        <br />
        <br />
        Se você chegou até aqui é porque a sua empresa contratante respeita e se
        preocupa com a saúde dos colaboradores, assim como nós nos preocupamos
        com o seu bem-estar e a sua privacidade. Por isso, solicitamos que leia
        atentamente os termos estipulados abaixo.
      </p>

      <p style={{ textAlign: 'left', fontWeight: 'bold' }}>Lembramos que:</p>
      <ul>
        <li style={{ textAlign: 'justify' }}>
          A {HSPW()}, startup lançada em 2020, é uma plataforma Health Tech de
          identificação e acompanhamento contínuo da saúde integral de todos os
          colaboradores de uma empresa. Reunindo um Dream Team de sócios como o
          psiquiatra <span className="fa-main">Jairo Bouer</span> e a jornalista
          e especialista em bem-estar{' '}
          <span className="fa-main">Glenda Kozlowski</span>, a {HSPW()} oferece
          uma solução completa em saúde preventiva e curativa através de seu
          ecossistema único para clientes e colaboradores.
        </li>
        <br />
        <li style={{ textAlign: 'justify' }}>
          <span className="fa-main">
            &quot;Cuidar das pessoas para que elas cuidem da sua empresa é um
            investimento com retorno garantido&quot;
          </span>
          , com a {HSPW()} a saúde dos colaboradores representa a saúde da
          empresa.
        </li>

        <br />
        <li style={{ textAlign: 'left' }}>
          Identificação das partes:
          <br />
          <ul>
            <br />
            <li style={{ textAlign: 'justify' }}>
              {HSPW()}: Plataforma de identificação e acompanhamento contínuo da
              saúde integral dos colaboradores de uma empresa. A {HSPW()}{' '}
              oferece uma solução completa em saúde preventiva e curativa
              através de seu ecossistema único para clientes e colaboradores.
            </li>
            <br />
            <li style={{ textAlign: 'justify' }}>
              <span className="fa-underline fa-bold">EMPRESA(S)</span>:
              Independente se escrita no plural ou singular, trata-se de pessoa
              jurídica que atuará como CONTRATANTE da {HSPW()}, utilizando a
              plataforma para o gerenciamento da saúde integral de seus
              colaboradores, prestadores de serviços, e/ou partes interessadas.
            </li>
            <br />
            <li style={{ textAlign: 'justify' }}>
              <span className="fa-underline fa-bold">USUÁRIOS</span>: São todas
              as pessoas físicas que, por meio de alguma relação com a empresa,
              utilizam o sistema oferecido pela {HSPW()}. Estas pessoas podem
              ser: colaboradores, familiares dos colaboradores, ou ainda
              candidatos de algum processo seletivo da empresa.ão os
              colaboradores vinculados à {bold('empresa')} que utilizam o
              sistema oferecido. Também podem ser pessoas relacionadas aos
              colaboradores, como dependentes e cônjuges.
            </li>
          </ul>
        </li>
        <br />
        <li className="fa-underline" style={{ textAlign: 'justify' }}>
          Os {bold('Usuarios')} estão cientes, desde já, que para acessar os
          sistemas da {HSPW(true)} deverão reportar à empresa o interesse em
          utilizar a plataforma. Os {bold('usuários')} que são colaboradores da
          empresa devem optar pela utilização identificada ou anônima do
          software.
        </li>
      </ul>

      <p style={{ textAlign: 'justify' }}>
        Os {bold('Usuários')} da plataforma têm total liberdade para realizar
        alterações cadastrais que contenham informações pessoais incorretas,
        assim como, na ausência de manutenção por previsão legal, solicitar a
        exclusão da conta a qualquer momento. Tal solicitação deverá ser
        realizada através do RH da {bold('empresa')} contratante.
        <br />
        <br />
        Os Termos e Condições de Uso constituem um Contrato firmado entre os{' '}
        {bold('Usuários')} (&quot;{bold('Você')}&quot;) e a{' '}
        {mainBold('HSPW SAÚDE PREVENTIVA LTDA')} (&quot;{HSPW()}&quot;), pessoa
        jurídica de direito privado estabelecida na Rua Menino Deus, nº 63,
        apartamento 102 C, bloco A, Centro, CEP 88020-210, na cidade de
        Florianópolis, SC, inscrita no CNPJ sob nº 40.087.992/0001-50, com
        contrato social arquivado na Junta Comercial do Estado de Santa Catarina
        sob o NIRE nº 42206389951.
      </p>

      <ol className="fa-ol-p">
        <li className="fa-li">
          {bold('FUNCIONAMENTO DA PLATAFORMA')}
          <ol className="fa-ol">
            <li className="fa-li">
              Através de um “voucher” (convite) enviado para o dispositivo do{' '}
              {bold('usuário')}, este poderá acessar a plataforma da {HSPW()} e
              complementar seu cadastro, criando um login e uma senha.
            </li>
            <li className="fa-li">
              Ao acessar a plataforma, o {bold('usuário')} pode aceitar, de
              forma opcional, receber notificações e/ou mensagens via SMS da{' '}
              {HSPW()}.
            </li>
            <li className="fa-li">
              Os {bold('usuários')} poderão utilizar a plataforma {HSPW()} para
              participar dos ciclos de avaliações, responder às perguntas
              diárias fornecidas pelo sistema, acessar e monitorar seu
              diagnóstico, acessar sua análise de resultados individuais,
              acessar recomendações com base em diagnósticos realizados e editar
              respostas incompletas ou incorretas.
            </li>
            <li className="fa-li">
              Os {bold('usuários')}, que são colaboradores da {bold('empresa')},
              poderão escolher se as suas informações serão repassadas de forma
              identificada ou anônima para a {bold('empresa')}, como se observa:
              <ol className="fa-ol">
                <li className="fa-li">
                  {underline('Identificado')}: A {bold('empresa')} receberá
                  relatórios com informações sobre a saúde integral do usuário,
                  com identificação do colaborador através de sua matrícula;
                </li>
                <li className="fa-li">
                  {underline('Anônimo')}: A {bold('empresa')} receberá
                  relatórios com informações sobre a saúde integral do{' '}
                  {bold('usuário')}, mas não poderá identificar sobre qual
                  colaborador são as informações repassadas.
                </li>
              </ol>
            </li>
            <li className="fa-li">
              Os {bold('usuários')} poderão acompanhar suas atividades através
              da plataforma, contudo, apenas {bold('usuários')} com credenciais
              autorizadas pela {bold('empresa')} terão acesso aos relatórios
              completos obtidos com as respostas de todos os {bold('usuários')}.
              <ol className="fa-ol">
                <li className="fa-li">
                  Os {bold('usuários')} com as credenciais autorizadas são
                  responsáveis pela segurança das informações de acesso à
                  plataforma (login e senha), responsabilizando-se por jamais
                  compartilhar tais informações, assim como com o dever de
                  reportar ao suporte da {HSPW()} {underline('imediatamente')}{' '}
                  em caso de esquecimento ou acesso indevido.
                </li>
              </ol>
            </li>

            <li className="fa-li">
              Os relatórios e diagnósticos fornecidos pela {HSPW()} serão, única
              e exclusivamente, elaborados com base nas informações fornecidas
              pelos {bold('usuários')}.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('TRATAMENTO DE DADOS PESSOAIS')}
          <ol className="fa-ol">
            <li className="fa-li">
              Para o cumprimento dos serviços que se propõe, a {HSPW()}{' '}
              precisará coletar e tratar informações pessoais dos{' '}
              {bold('usuários')}, as quais estão detalhadas em nossa política de
              privacidade.
            </li>
            <li className="fa-li">
              A {HSPW()} necessita das informações acima para poder prestar o
              serviço e suporte mais adequado aos {bold('usuários')} e{' '}
              {bold('empresas')}, assim como para garantir a segurança e a
              transparência com total respeito aos preceitos trazidos pela Lei
              Geral de Proteção de Dados Pessoais (LGPD) e demais disposições
              legais.
            </li>
            <li className="fa-li">
              A {HSPW()} poderá utilizar os dados referentes ao histórico de
              saúde e características físicas do {bold('usuário')} para
              identificar padrões e alterações na saúde física e mental do{' '}
              {bold('usuários')}, assim como elaborar e fornecer relatórios,
              estudos de casos, e outros materiais de caráter científico e/ou
              estatístico para uso próprio da {HSPW()} ou de terceiros, desde
              que respeitada a anonimização dos dados pessoais.
            </li>
            <li className="fa-li">
              São concedidos aos {bold('usuários')} todos os direitos sobre as
              suas informações pessoais, de forma que este deverá entrar em
              contato com o RH da {bold('empresa')} contratante ou diretamente
              com o canal de suporte da {HSPW()} para discutir tais questões.
            </li>
            <li className="fa-li">
              A {HSPW()} empregará seus melhores esforços para respeitar e
              proteger os dados e as informações pessoais contra perda, roubo ou
              qualquer modalidade de uso indevido, bem como contra acesso não
              autorizado, divulgação, alteração e destruição.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('RESPONSABILIDADES E DIREITOS DOS USUARIOS')}
          <ol className="fa-ol">
            <li className="fa-li">
              Os {bold('usuários')} da plataforma {HSPW()} se comprometem a (i)
              não compartilhar informações falsas; (ii) não divulgar para
              terceiros informações obtidas através da plataforma sem antes
              obter autorização da {HSPW()}; (iii) conceder permissão para a{' '}
              {HSPW()} atualizar os dados obtidos no software, seja em
              computadores, smartphones, tablets ou outros dispositivos
              correlatos; (iv) respeitar integralmente os padrões de conduta e
              os termos de uso da plataforma em suas comunicações dentro do
              software, observando todas as disposições destes Termos, bem como
              a legislação brasileira.
            </li>
            <li className="fa-li">
              Ao aceitar estes Termos ou acessar os URLs fornecidos pela{' '}
              {HSPW()}, o {bold('usuário')} reconhece que leu, entendeu e
              concordou em cumprir os presentes termos.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('LIMITES DE RESPONSABILIDADE')}
          <ol className="fa-ol">
            <li className="fa-li">
              Cada {bold('usuário')} é o único e exclusivo responsável por toda
              e qualquer informação adicionada na plataforma. Em nenhuma
              hipótese a {HSPW()} se responsabiliza pelas informações, valores,
              dados ou coleta de informações adicionados por quaisquer dos{' '}
              {bold('usuários')}, que reconhecem expressamente sua
              responsabilidade pessoal, assumindo os riscos cíveis,
              administrativos e criminais.
            </li>
            <li className="fa-li">
              A {HSPW()} irá emitir relatórios às {bold('empresas')} com base
              nas informações repassadas pelos {bold('usuários')}, sem qualquer
              interpretação ou julgamento de valor.
              <ol className="fa-ol">
                <li className="fa-li">
                  Os relatórios emitidos pela {HSPW()} poderão ser identificados
                  ou anonimizados, a depender da devida autorização do usuário,
                  os quais são colaboradores da {bold('empresa')}e poderão optar
                  pela sua identificação ou não (uso identificado ou anônimo).
                </li>
                <li className="fa-li">
                  Nos casos de utilização da plataforma para processos
                  seletivos, os relatórios dos {bold('usuários')} serão emitidos
                  de forma identificada à {bold('empresa')}, a fim de que esta
                  analise para sua tomada de decisão.
                </li>
                <li className="fa-li">
                  A HSPW também poderá, caso queira o usuário colaborador da{' '}
                  {bold('empresa')}, ser utilizada por seus familiares. Os
                  relatórios dos {bold('usuários')} familiares ficarão
                  disponíveis de forma identificada apenas para estes, enquanto
                  que, para a {bold('empresa')}, poderão ser repassados única e
                  exclusivamente na anonimizada.
                </li>
              </ol>
            </li>
            <li className="fa-li">
              A {HSPW()}, através do RH da {bold('empresa')}, oferece suporte
              técnico integral aos {bold('usuários')} da plataforma.
            </li>

            <li className="fa-li">
              A {HSPW()} não se responsabiliza pela utilização, tratamento,
              ações, ou qualquer outra atitude tomada pela {bold('empresa')} em
              relação aos relatórios emitidos.
            </li>

            <li className="fa-li">
              Ainda que a {HSPW()} possa indicar e recomendar práticas para
              tratamentos físicos e/ou mentais, esta não se responsabiliza pelo
              resultado definitivo, como também pelo agravamento de possíveis
              enfermidades, distúrbios, limitações, ou qualquer outro
              diagnóstico médico constatado ou não pela plataforma.
            </li>

            <li className="fa-li">
              A {HSPW()} não se responsabiliza por perdas e/ou prejuízos
              causados por interrupções na prestação do serviço causadas por
              motivos fortuitos, como desastres naturais, acidentes, incêndios,
              enchentes ou por ausência do {bold('usuário')} ao serviço
              contratado.
            </li>

            <li className="fa-li">
              A {HSPW()} não assegura que o serviço será ininterrupto ou livre
              de falhas. No entanto, se a plataforma não estiver disponível
              devido à manutenção planejada, procuraremos notificar todos os{' '}
              {bold('usuários')} com pelo menos 5 (cinco) dias de antecedência.
            </li>
            <li className="fa-li">
              A {HSPW()} possui relação exclusivamente contratual com a{' '}
              {bold('empresa')}, não fazendo parte desta e nem possuindo
              qualquer vínculo senão o de mera prestação de serviços. O serviço
              prestado, sob nenhuma hipótese, estabelece vínculo empregatício ou
              de representação entre as partes, de forma que a {bold('empresa')}{' '}
              é totalmente responsável por suas próprias obrigações (
              tributárias, trabalhistas e previdenciárias), não podendo, em
              hipótese nenhuma, recair tais obrigações sobre a {HSPW()}.
            </li>
            <li className="fa-li">
              O uso da plataforma oferecida pela {HSPW()} não cria uma relação
              de trabalho ou de agência entre esta e qualquer colaborador{' '}
              {bold('usuário')} da {bold('empresa')} na execução do contrato, os
              quais terão vínculo empregatício única e exclusivamente com a
              própria {bold('empresa')}.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('PROPRIEDADE INTELECTUAL')}
          <ol className="fa-ol">
            <li className="fa-li">
              Toda a identidade visual disponibilizada na plataforma, exceto por
              aquela advinda das {bold('empresas')}, é de propriedade única e
              exclusiva da {HSPW()} assim como todos os softwares utilizados,
              marca, patentes, segredos comerciais e direitos autorais.
            </li>
            <li className="fa-li">
              Os {bold('usuários')} se comprometem a não fazer qualquer tipo de
              uso da marca, identidade visual, conteúdos e materiais elaborados
              e disponibilizados pela {HSPW()}, ou qualquer outro tipo de
              propriedade intelectual do sistema sem que haja o prévio e
              expresso consentimento da plataforma, bem como se comprometem a
              não reproduzir, modificar, traduzir, adaptar, fazer engenharia
              reversa, desmontar ou criar derivações dos softwares ou da
              plataforma.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('SUSPENSÃO OU CANCELAMENTO DO CADASTRO')}
          <ol className="fa-ol">
            <li className="fa-li">
              Havendo infrações ao disposto nos termos de uso da {HSPW()}, bem
              como à legislação, o {bold('usuário')} infrator poderá ter seu
              cadastro suspenso ou cancelado, a depender da gravidade da
              infração.
            </li>
            <li className="fa-li">
              Em caso de cancelamento da conta do {bold('usuário')}, a {HSPW()}{' '}
              notificará o {bold('usuário')} infrator e o responsável pela{' '}
              {bold('empresa')}, com pelo menos 5 (cinco) dias de antecedência à
              aplicação da penalidade. No período compreendido entre a
              notificação e a efetivação da sanção, o {bold('usuário')} terá sua
              conta bloqueada na Plataforma {HSPW()} e será possibilitado a
              apresentação de justificativas sobre os atos tidos como ilegais,
              depois disso a {HSPW()} analisará a justificativa e definirá a
              sanção a ser aplicada ou desbloqueio da conta.
            </li>
            <li className="fa-li">
              O bloqueio do {bold('usuário')} na plataforma, em nada altera o
              uso desta por parte da {bold('empresa')} e dos demais{' '}
              {bold('usuários')}, desde que não seja comprovado a ligação da{' '}
              {bold('empresa')} com o respectivo ato ilegal.
            </li>
            <li className="fa-li">
              O cancelamento do cadastro por parte do {bold('usuário')} deverá
              ser requerido ao RH da {bold('empresa')}.
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('REGISTROS E RELAÇÕES')}
          <ol className="fa-ol">
            <li className="fa-li">
              Toda {bold('empresa')} que convidar seus colaboradores, gestores,
              prestadores de serviços, candidatos de processos seletivos ou
              demais partes interessadas para se cadastrarem na plataforma da{' '}
              {HSPW()}, compromete-se em comunicar a estes todas informações
              relevantes, tais quais, mas não se limitando a: finalidade,
              funcionalidades e responsabilidades.
            </li>
            <li className="fa-li">
              Cabe à {bold('empresa')}, definir a quem será disponibilizado
              algum tipo de credencial exclusiva, bem como definir os limites de
              tal credencial de acesso na plataforma.
              <ol className="fa-ol">
                <li className="fa-li">
                  Cabe à {bold('empresa')}, solicitar à {HSPW()}, a alteração,
                  limitação, e/ou exclusão das credenciais de determinado{' '}
                  {bold('usuário')}, com aviso prévio de 5 dias úteis (cinco
                  dias úteis).
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('PROIBIÇÕES')}
          <ol className="fa-ol">
            <li className="fa-li">
              É expressamente vedado ao {bold('Usuário')}:
              <ol className="fa-ol">
                <li className="fa-li">
                  Licenciar, vender, alugar, arrendar, transferir, ceder,
                  distribuir, hospedar ou, de outra forma, explorar
                  comercialmente o serviço da {HSPW()}, seja no todo ou em
                  parte, ou qualquer conteúdo exibido.
                </li>
                <li className="fa-li">
                  Modificar, fazer trabalhos derivados, desmontar, compilar
                  reversamente ou fazer engenharia reversa de qualquer parte da
                  plataforma {HSPW()}.
                </li>
                <li className="fa-li">
                  Acessar a plataforma {HSPW()} com intenção de construir um
                  site, produto ou serviço similar ou competitivo.
                </li>
                <li className="fa-li">
                  Disponibilizar as informações obtidas através da plataforma a
                  terceiros, sem a devida autorização pela {HSPW()}.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <br />
        <li className="fa-li">
          {bold('ATUALIZAÇÃO E VIGÊNCIA DOS TERMOS DE USO')}
          <ol className="fa-ol">
            <li className="fa-li">
              A {HSPW()} busca sempre aprimorar sua plataforma e os serviços
              oferecidos, de forma que, eventualmente, estes Termos de Uso e a
              nossa Política de Privacidade poderão necessitar de atualizações.
              As referidas alterações/atualizações serão sempre notificadas
              diretamente na plataforma da {HSPW()}.
            </li>
            <li className="fa-li">
              O uso continuado de nosso serviço, após a notificação de tais
              mudanças, indicará seu reconhecimento e concordância em ficar
              vinculado aos termos e condições das mesmas.
            </li>
            <li className="fa-li">
              Todos os termos e políticas {HSPW()} passam a vigorar a partir do
              momento em que o {bold('usuário')} se cadastra na plataforma e
              adere a eles.
            </li>
            <li className="fa-li">
              Sua vigência será por prazo indeterminado, vigorando enquanto
              durar a relação jurídica entre a {HSPW()} e a {bold('empresa')}{' '}
              contratante, estendendo-se aos colaboradores da {bold('empresa')}{' '}
              que fazem uso da plataforma.
            </li>
          </ol>
        </li>
      </ol>
      {showCollapse && (
        <p className="text-center">
          <a
            data-toggle="collapse"
            href="#collapseUseTerms"
            role="button"
            aria-expanded="false"
            aria-controls="collapseUseTerms"
          >
            (recolher os Termos de Uso)
          </a>
        </p>
      )}
      {showCheck && (
        <div className="col-12 m-3 custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input bg-custom-main"
            id="chkTermsUse"
            onChange={handleCheck.bind(this)}
          ></input>
          <label
            className="custom-control-label bold text-left"
            htmlFor="chkTermsUse"
          >
            Li e aceito os Termos de Uso acima descritos.
          </label>
        </div>
      )}
    </div>
  )
}

export default TermsOfUse
