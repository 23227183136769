import React from 'react'
import './TermsOfUse.css'
import { HSPW, bold, underline, mainBold } from '../utils/functions'

const PrivacyPolicy = ({ showCollapse, showCheck = false, handleCheck }) => {
  return (
    <div className="card card-body border-0 mr-2 p-0 termos">
      <div className="col-12 text-center pb-3">
        <img
          className="img-fluid "
          alt="HSPW"
          src="/images/logo_HSPW_bounce.png"
          style={{ width: '60px', height: 'auto' }}
        />
      </div>

      <h6 className="text-center bold pb-2">
        POLÍTICA DE PRIVACIDADE DA {HSPW()}
      </h6>
      <p style={{ textAlign: 'justify' }}>
        Seus dados pessoais (&quot;dados&quot;) são bens valiosos que devem ser
        preservados, e faremos tudo o que estiver ao nosso alcance para
        protegê-los e manter a sua confiança. Por isso, Você precisa saber
        exatamente como suas informações serão utilizadas. E justamente por esse
        motivo que criamos a presente Política de Privacidade - sem juridiquês e
        sem enrolação, objetiva e eficiente, tal qual nossos serviços.
        <br />
        <br />
        Nós, da {mainBold('HSPW SAÚDE PREVENTIVA LTDA')} (pode nos chamar apenas
        &quot;{HSPW()}&quot;) somos uma plataforma Health Tech de identificação
        e acompanhamento contínuo da saúde integral de todos os colaboradores de
        uma empresa a custos acessíveis. Através de uma metodologia própria,
        reunimos dados de comportamento relacionados à saúde integral, apurados
        por meio de pesquisas diretas com os participantes, e baseados em
        algoritmos, oferecendo evidências e análise de dados para suportar
        abordagens estratégicas nas questões de Saúde e Bem-estar do capital
        humano das empresas.
        <br />
        <br />E para que isso seja possível, lidamos diariamente com uma série
        de dados pessoais, ao passo que precisamos garantir a sua privacidade e
        proteger os seus dados! Assim, é muito importante que Você leia
        atentamente nossa Política de Privacidade, conhecendo e compreendendo o
        destino e o uso das informações pessoais colhidas.
        <br />
        <br />
        {bold('Lembramos que:')}
        <br />
        <br />A {HSPW()} é uma plataforma Health Tech de identificação e
        acompanhamento contínuo da saúde integral de todos os colaboradores de
        uma empresa. Através de uma metodologia própria, reúne dados de
        comportamento relacionados à saúde integral, apurados por meio de
        pesquisas diretas com os participantes, e baseados em algoritmos,
        oferecendo evidências e análise de dados para suportar abordagens
        estratégicas nas questões de Saúde e Bem-estar do capital humano das
        empresas.
      </p>
      <ul>
        <li className="fa-ul">
          A {HSPW()}, startup lançada em 2020, é uma plataforma Health Tech de
          identificação e acompanhamento contínuo da saúde integral de todos os
          colaboradores de uma empresa. Reunindo um Dream Team de sócios como o
          psiquiatra <span className="fa-main">Jairo Bouer</span> e a jornalista
          e especialista em bem-estar{' '}
          <span className="fa-main">Glenda Kozlowski</span>, a {HSPW()} oferece
          uma solução completa em saúde preventiva e curativa através de seu
          ecossistema único para clientes e colaboradores.
        </li>
        <br />
        <li className="fa-ul">
          <span className="fa-main">
            &quot;Cuidar das pessoas para que elas cuidem da sua empresa é um
            investimento com retorno garantido&quot;
          </span>
          , com a {HSPW()} a saúde dos colaboradores representa a saúde da
          empresa.
        </li>
      </ul>
      <ul>
        {bold('Descrição das partes')}
        <br />
        <br />
        <li className="fa-ul">
          {underline('EMPRESAS', true)}: Independente se escrita no plural ou
          singular, trata-se de pessoa jurídica que atuará como CONTRATANTE da{' '}
          {HSPW()}, utilizando a plataforma para o gerenciamento da saúde fisica
          e mental de seus colaboradores, prestadores de serviços, e/ou partes
          interessadas.
        </li>
        <br />
        <li className="fa-ul">
          {underline('USUÁRIOS', true)}: São todas as pessoas físicas que, por
          meio de alguma relação com a {bold('empresa')}, utilizam o sistema
          oferecido pela {HSPW()}. Estas pessoas podem ser: colaboradores,
          familiares dos colaboradores, ou ainda candidatos de algum processo
          seletivo da empresa.
        </li>
        <br />
        <li className="fa-ul">
          {underline('REPRESENTANTES LEGAIS DA EMPRESA', true)}: Ou apenas
          “Representante(s) da empresa”, são as pessoas físicas nominadas pela
          empresa, para realizar os contatos, receber notificações relevantes de
          uso, negociar novas condições, entre outras atividades relacionadas ao
          serviço da {HSPW()}.
        </li>
      </ul>

      <ol className="fa-ol-p">
        <li className="fa-li">
          {bold('Informações pessoais que coletamos')}
          <p>
            Todas as informações citadas serão coletadas, processadas,
            armazenadas, tratadas e ficarão disponíveis para consulta pela{' '}
            {HSPW()}, sempre que pertinente, ou viabilizadas por um uso
            permitido e necessário, ou ainda, mediante requisição do titular dos
            dados pessoais para cumprimento de deveres oriundos da legislação.
          </p>
          <ol className="fa-ol-p">
            <li className="fa-li">
              {' '}
              {bold('Se você é nosso usuario,')} você possui algum vínculo, seja
              ele direto ou indireto, com uma empresa que contratou os serviços
              da {HSPW()} para acompanhar a saúde física e mental de suas partes
              interessadas, e por isso, você realizou seu cadastro na plataforma
              da {HSPW()}. Assim, nós podemos coletar as seguintes informações:
              sexo, data de nascimento, nacionalidade, UF da naturalidade,
              matrícula, local de atuação (filial, departamento, time), cargo,
              data de admissão, telefone, e-mail, modelo de contratação,
              quantidade de atestados, faltas, férias dos últimos 12 (doze)
              meses, data de nascimento, informações de residência, orientação
              sexual, altura, peso, histórico de doenças pregressas tipo
              sangúineo, origem étnica, dados relacionados à plano de saúde,
              histórico de medicamentos.
            </li>
            <br />
            <li className="fa-li">
              {bold('Se você é representante legal da empresa')}, você é o
              responsável, em nome da empresa, para realizar os contatos,
              receber notificações relevantes de uso, e negociar novas
              condições. Assim, coletamos, Nome, CPF, sexo, data de nascimento,
              estado civil, RG, data da emissão do RG, órgão expedidor, CPF,
              Nacionalidade, UF da naturalidade, Cidade da naturalidade, CEP,
              Estado da Federação, Cidade, Logradouro, Número da residência,
              bairro, Complemento, modalidade de residência, dados bancários,
              telefone para contato, e-mail.
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Para qual finalidade utilizamos dados ')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              Coletamos algumas informações importantes para que possamos
              prestar o serviço e suporte mais adequado aos nossos clientes,
              usuários e parceiros, assim como para garantir a segurança e a
              transparência com total respeito aos preceitos trazidos pela Lei
              Geral de Proteção de Dados Pessoais (LGPD) e demais disposições
              legais.
            </li>
            <br />
            <li className="fa-li">
              Utilizamos dados referentes ao histórico de saúde e
              características físicas para, com o uso da nossa metodologia,
              identificar padrões e alterações na saúde física e mental do
              usuário.
            </li>
            <br />
            <li className="fa-li">
              Utilizamos os dados referentes à saúde, características físicas e
              diagnósticos, para elaborar e fornecer relatórios, estudos de
              casos, e outros materiais de caráter científico para uso próprio
              da {HSPW()} ou de terceiros, desde que respeitada a anonimização
              dos dados pessoais.
            </li>
            <br />
            <li className="fa-li">
              Utilizamos dados pessoais para o envio de comunicados,
              informativos, diagnósticos, convites, ofertas de serviços da{' '}
              {HSPW()}
              e/ou de seus parceiros com uso de variados meios de comunicação.
            </li>
            <br />
            <li className="fa-li">
              Elaboramos e/ou fornecemos aos nossos parceiros e representantes
              das empresas que contratam o serviço da {HSPW()}, informações
              estatísticas relevantes, sobre usuários, através de relatórios e
              métricas.
              <ol className="fa-ol-p">
                <li className="fa-li">
                  Garantida a finalidade, haverá também anonimização dos dados
                  dos titulares.
                </li>
                <br />
                <li className="fa-li">
                  Possuímos estipulações contratuais e cobramos de nossos
                  parceiros a adoção de medidas de segurança, técnicas e
                  administrativas aptas a proteger os dados pessoais de acessos
                  não autorizados e de situações acidentais ou ilícitas de
                  destruição, perda, alteração, comunicação ou qualquer forma de
                  tratamento inadequado ou ilícito.
                </li>
              </ol>
            </li>
            <br />
            <li className="fa-li">
              Mantemos a segurança das operações (comerciais, jurídicas,
              mercadológicas, internas, entre outras) para evitar casos de
              fraudes no âmbito das operações intermediadas pela {HSPW()}
              diretamente, ou por meio de terceiros quando necessário.
            </li>
            <br />
            <li className="fa-li">
              Os dados pessoais coletados serão tratados apenas enquanto houver
              finalidade que justifique este tratamento, de forma que, para
              utilizá-los para quaisquer outras atividades não citadas nesta
              política, solicitaremos sempre o seu consentimento.
            </li>
            <br />
            <li className="fa-li">
              A {HSPW()} utiliza os dados recebidos EXCLUSIVAMENTE para a
              realizar a prestação de serviços contratada, sem realizar
              enriquecimento, venda, ou qualquer outro ato distinto do exposto
              na presente Política de Privacidade.
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Confira os seus direitos')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              Os nossos clientes e parceiros poderão exercer, mediante
              requisição ou acesso direto, sempre acompanhada de prova de
              identidade, todos aqueles direitos previstos no artigo 18 da lei
              no 13.709 de 2018, basta acessar nosso portal da privacidade!{' '}
            </li>
            <li className="fa-li">
              Registramos que a {HSPW()} poderá solicitar algumas informações
              complementares para fins de comprovação da sua identidade,
              buscando impedir fraudes.
            </li>
            <li className="fa-li">
              Cientificamos que algumas solicitações podem não ser respondidas
              de forma imediata e nem sempre a {HSPW()} atenderá a todas as
              solicitações realizadas pelos titulares de dados, tendo em vista
              suas obrigações legais e necessidade de manutenção de determinadas
              informações.{' '}
            </li>
            <li className="fa-li">
              Nos casos em que o usuário, ainda em relação com a empresa, mas
              por conta própria, solicitar direitos como exclusão e revogação, a
              {HSPW()} notificará previamente o representante legal da empresa a
              qual o usuário está vinculado.
            </li>
            <li className="fa-li">
              O encerramento das relações de serviço ou trabalho entre a empresa
              e o usuário não acarreta em automática exclusão, suspensão,
              bloqueio, ou qualquer outra ação de direito do usuário, por parte
              da {HSPW()}.{' '}
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Como utilizamos as informações pessoais')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              Você já sabe que usamos dados pessoais para prestar nossos
              melhores serviços, mas também utilizamos dados para nos comunicar
              com você, personalizar sua experiência, cumprir nossas obrigações
              legais e garantir sua segurança.
            </li>
            <li className="fa-li">
              Seguindo os Termos de Uso da {HSPW()} fornecemos e compartilhamos
              com as empresas, informações a respeito dos seus colaboradores,
              familiares dos colaboradores, e ainda, candidatos de processo
              seletivo, respeitando a decisão do mesmo em identificar ou
              anonimizar seus dados pessoais.
            </li>
            <li className="fa-li">
              No trabalho com nossos parceiros podemos compartilhar, com o
              devido respeito à necessidade e finalidade, dados pessoais com
              eles, conforme razoavelmente necessário segundo os objetivos desta
              política ou no cumprimento de obrigações legais. destacando os
              casos de:
              <ol className="fa-ol-p">
                <li className="fa-li">
                  Processo judicial, em andamento ou potencial;
                </li>
                <li className="fa-li">
                  Para exercer, defender ou estabelecer direitos nossos ou de
                  terceiros;
                </li>
                <li className="fa-li">
                  Em caso de cumprimento de ordem emanada por autoridade
                  competente.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Segurança da informação')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              Os Usuários se comprometem com a veracidade das informações
              prestadas, responsabilizando-se em caso de ilegalidade cometida
              mediante fornecimento de informações falsas e/ou não autorizadas.
            </li>

            <li className="fa-li">
              A {HSPW()} empregará seus melhores esforços para respeitar e
              proteger os dados e as informações pessoais contra perda, roubo ou
              qualquer modalidade de uso indevido, bem como contra acesso não
              autorizado, divulgação, alteração e destruição.
              <ol className="fa-ol-p">
                <li className="fa-li">
                  É vedado, aos colaboradores e/ou terceirizados da {HSPW()},
                  reproduzir e/ou compartilhar sem autorização da {HSPW()},
                  qualquer conteúdo em posse da {HSPW()} , por qualquer meio de
                  comunicação e/ou reprodução.
                </li>
              </ol>
            </li>
            <li className="fa-li">
              Todas as informações ou dados pessoais inseridos no ambiente
              digital ou convertidos do meio físico para o virtual serão, quando
              necessário, criptografados pela {HSPW()}.
            </li>
            <li className="fa-li">
              A {HSPW()} não se responsabiliza por qualquer vazamento,
              divulgação ou mau tratamento de dados que não tenha se dado por
              sua responsabilidade, direta ou indireta.
            </li>
            <li className="fa-li">
              A {HSPW()} garante que, da sua parte, apenas os colaboradores e/ou
              terceiros necessários para tratar de determinado dado e/ou
              informação receberam credenciais para tais funções.
            </li>
            <li className="fa-li">
              A {HSPW()} garante que, nos casos em que a operação não seja
              prejudicada, haverá anonimização dos dados pessoais no tratamento
              de dados realizado diretamente pela {HSPW()}.
            </li>
            <li className="fa-li">
              Cabe à empresa, através de seu representante legal, solicitar à
              {HSPW()} a autorização, suspensão, e/ou cancelamento de
              credenciais específicas para determinados usuários, conforme sua
              vontade e orientação.
            </li>
            <li className="fa-li">
              Aquele que possuir credencial de acesso para os dados pessoais e
              informações dos quais esta política trata e/ou tratadas como
              sigilosas, será o responsável pelo zelo e uso correto das suas
              credenciais de acesso, podendo ser responsabilizado pessoalmente
              pelo uso indevido de suas credenciais.
            </li>
            <li className="fa-li">
              A {HSPW()} esclarece que pode enviar ou tratar qualquer informação
              fora do território nacional.
              <ol className="fa-ol-p">
                <li className="fa-li">
                  Nos casos de envio ou tratamento de informações fora do
                  território nacional, a {HSPW()} garante que tais países
                  possuem e cumprem medidas de proteção de dados, minimamente do
                  mesmo nível que o país de origem.
                </li>
              </ol>
            </li>
            <li className="fa-li">
              Os dados pessoais, e outras informações tratadas para quaisquer
              dos objetivos citados nesta política, não serão mantidos por mais
              tempo do que o estritamente necessário para a conclusão das
              finalidades a que se destinarem, salvo quando devidamente
              anonimizados, seguindo os Artigos 12 e 16 da LGPD.
              <ol className="fa-ol-p">
                <li className="fa-li">
                  Os dados e documentos eletrônicos que contenham dados pessoais
                  podem permanecer retidos por mais tempo do que o necessário
                  para seus fins, se e somente se, nos casos de: obrigação
                  legal, redução do risco de fraude, pleno exercício do direito
                  de defesa.
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Sobre a política de privacidade')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              A {HSPW()} busca sempre aprimorar seu sistema para garantir
              serviços e proteções de qualidade, ao passo que, eventualmente,
              essa Política de Privacidade poderá ser atualizada ou alterada
              unilateralmente pela {HSPW()}.
              <ol className="fa-ol-p">
                <li className="fa-li">
                  Estamos sempre melhorando nossa Política e as mudanças serão
                  refletidas nesta página. Então, antes de usar um de nossos
                  serviços dê uma olhadinha aqui. Quando publicarmos alterações
                  na Política, atualizaremos a data em que a última alteração
                  foi publicada. Lembre-se: Ao continuar a acessar os Serviços
                  depois que tais alterações passarem a valer, você estará
                  concordando em estar vinculado à nova versão da Política.
                </li>
              </ol>
            </li>
            <li className="fa-li">
              Quaisquer alterações a esta Política entrarão em vigor após 01
              (um) dia da comunicação.
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Vigência da Política de Privacidade')}
          <ol className="fa-ol-p">
            <li className="fa-li">
              Está Política de Privacidade passa a vigorar a partir do momento
              em que o usuário realiza seu cadastro na plataforma {HSPW()} e
              concorda com os termos desta política.
            </li>
            <li className="fa-li">
              A vigência da presente Política de Privacidade será por prazo
              indeterminado, vigorando enquanto durar a relação contratual entre
              as partes e os deveres dela decorrentes.
            </li>
          </ol>
        </li>
        <br />
        <li className="fa-li">
          {bold('Converse com a ')}
          {HSPW()}
          <b>!</b>
          <ol className="fa-ol-p">
            <li className="fa-li">
              Caso precise de qualquer suporte ou tenha alguma dúvida, pedido ou
              sugestão em relação a essa Política ou demais questões de
              privacidade, estaremos sempre à sua disposição. Basta contatar
              nossa central de atendimento ou conversar diretamente com nosso
              responsável de privacidade (encarregado de dados), pelo e-mail:
              ti@hspw.com
            </li>
          </ol>
        </li>
      </ol>
      {showCollapse && (
        <p className="text-center">
          <a
            data-toggle="collapse"
            href="#collapsePolPriv"
            role="button"
            aria-expanded="false"
            aria-controls="collapsePolPriv"
          >
            (recolher a Política de Privacidade)
          </a>
        </p>
      )}
      {showCheck && (
        <div className="col-12 m-3 custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input bg-custom-main"
            id="chkPrivacyPol"
            onChange={handleCheck.bind(this)}
          ></input>
          <label
            className="custom-control-label bold text-left"
            htmlFor="chkPrivacyPol"
          >
            Li e aceito a Política de Privacidade acima descrita.
          </label>
        </div>
      )}
    </div>
  )
}

export default PrivacyPolicy
